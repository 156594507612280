<template>
    <div class="register">
        <div class="content">
            <div class="title">
                <img src="../assets/sgy2.png">
            </div>
            <div class="subtitle">用户注册</div>
            <div class="block">
                <div class="ext">
                    <span><a href="/login">已有账号，去登录 ></a></span>
                </div>
                <div class="zidingyi">
                    <span><span class="mark"><span>*</span></span>选择角色</span>
                    <div style="margin-left:10px" class="click-mod" :class="role_id===0?'selected':''" @click="changeRoleId(0)"><span />个人用户</div>
                    <div class="click-mod" :class="role_id===1?'selected':''" @click="changeRoleId(1)"><span />企业用户</div>
                    <div class="click-mod" :class="role_id===2?'selected':''" @click="changeRoleId(2)"><span />院校用户</div>
                </div>
                <input type="password" autocomplete="new-password" hidden>
                <Cinput v-if="role_id===0" v-model="username" title="姓名"/>
                <Cinput v-if="role_id===0" v-model="workplace" title="院校/单位名称"/>
                <Cinput v-if="role_id===0" v-model="department_name" title="院系/部门"/>
                <Cinput v-if="role_id===1" v-model="workplace" title="企业名称"/>
                <Cinput v-if="role_id===1" v-model="username" title="联系人"/>
                <Cinput v-if="role_id===2" v-model="workplace" title="院校名称"/>
                <Cinput v-if="role_id===2" v-model="username" title="老师姓名"/>
                <Cinput v-if="role_id===2" v-model="department_name" title="所属院系"/>
                <Cinput v-if="role_id!=0" v-model="position" title="职务"/>
                <Cinput v-if="role_id===1" v-model="department_name" title="所属部门"/>
                <Cinput v-if="role_id===1" v-model="email" title="电子邮箱"/>
                
                <Cinput title="手机号码" v-model="phone" />
                <vue-simple-verify width='313' style="margin: 10px 33px;" ref="verify" @success="success" />
                <div class="ver-block">
                    <input v-model="verCode" class="input-large ext-input" type="text">
                    <div v-loading="buttonLoading" class="get-code" @click="getCode">{{codeTxt}}</div>
                </div>
                <Cinput style="margin-top: 15px;" title="密码" type="password" v-model="password" />
                <Cinput title="确认密码" type="password" v-model="repassword" />
                <div class="active btn" @click="next">注册</div>
            </div>
        </div>
        
    </div>
</template>
<script>
import Cinput from './userInput';
import VueSimpleVerify from 'vue-simple-verify'
import 'vue-simple-verify/dist/vue-simple-verify.css'
export default {
    data() {
        return {
            role_id: 0, //0个人用户，1企业用户，2院校用户
            phone:'',
            password:'',
            isRemeber: false,
            hasDrag:false,
            verCode:'',
            codeTxt:'获取验证码',
            buttonLoading: false,
            pageLoading: false,
            username:'',//姓名/联系人/老师姓名
            workplace:'',//工作单位/企业名称/院校名称
            department_name:'',//所属部门/所属院系
            position:'',//职务
            email:'',//邮箱
            repassword:'',//确认密码
        }
    },
    watch:{
        role_id(){
        }
    },
    computed:{
       
    },
    beforeMount() {
        if(this.$route.query.roleId){
            this.role_id = Number(this.$route.query.roleId);
        }
    },
    components:{
        Cinput,
        'vue-simple-verify':VueSimpleVerify,
    },
    methods: {
        changeRoleId(val){
            this.$router.push('/register?roleId='+val);
            location.reload();
        },
        resetData(){
            this.username = '';
            this.workplace = '';
            this.department_name = '';
            this.position = '';
            this.email = '';
            this.repassword = '';
            this.verCode = '';
            this.codeTxt = '';
            this.password = '';
            this.phone = '';
            this.$forceUpdate();
        },
        async next(){
          if(!this.validataData()){
            return false;
          }
          if(this.password !== this.repassword){
              this.$toast('输入的两次密码不一致');
              return false;
          }
          this.pageLoading = true;
          try {
            const res = await this.$http.post('/api/user/register',{
              role_id:this.role_id,
              username:this.username,
              workplace:this.workplace,
              department_name:this.department_name,
              position:this.position,
              email:this.email,
              mobile:this.phone,
              password:this.password,
              repassword:this.repassword,
              code:this.verCode,
              })
            this.pageLoading = false;
            if(res){
                // localStorage.setItem('shouguancloud_token',res.userinfo.token);
                // this.$store.commit('CHANGE_STATE',{userInfo:res.userinfo});
                this.$toast('您的账号已注册，请联系管理员审核开通', {duration: 2500});
                const tem = setTimeout(() => {
                    this.$router.push('/');
                    clearTimeout(tem);
                }, 3000);
            }
          } catch (error) {
            this.pageLoading = false;
            this.$toast(error.message||'请求失败');
          }
          
        },
        success(){
          this.hasDrag = true;
        },
        validataData(){
            if(this.username === ''){
                this.$toast(this.role_id===0?'请输入姓名':this.role_id===1?'请输入联系人':'请输入老师姓名');
                return false;
            }
            if(this.workplace === ''){
                this.$toast(this.role_id===0?'请输入院校/单位名称':this.role_id===1?'请输入企业名称':'请输入院校名称');
                return false;
            }
            if(this.department_name === ''){
                this.$toast(this.role_id===0?'请输入院系/部门':this.role_id===1?'请输入所属部门':'请输入所属院系');
                return false;
            }
            if(this.role_id!=0&&this.position === ''){
                this.$toast('请输入职务');
                return false;
            }
            const emailReg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$");
            if(this.role_id===1&&this.email===''){
                this.$toast('请输入邮箱地址');
                return false;
            }else if(this.role_id===1&&!emailReg.test(this.email)){
                this.$toast('请输入正确的邮箱地址');
                return false;
            }
            const myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            const phone = this.phone;
            if(phone===''){
                this.$toast('请输入手机号');
                return false;
            }else if(!myreg.test(phone)){
                this.$toast('请输入正确的手机号');
                return false;
            }
            if(this.verCode===''){
                this.$toast('请输入验证码');
                return false;
            }
            if(this.password===''){
                this.$toast('请输入密码');
                return false;
            }
            if(this.repassword===''){
                this.$toast('请输入确认密码');
                return false;
            }
            return true;
        },
        async getCode(){
          const phone = this.phone;
          if(this.codeTxt !== '获取验证码'){
            return false;
          }
          const myreg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
          if(this.phone === ''){
                this.$toast('请输入手机号');
                return false;
            }else if(!myreg.test(phone)){
                this.$toast('请输入正确的手机号');
                return false;
            }
            else if(!this.hasDrag){
                this.$toast('请按住上方滑块，拖动到最右边');
                return false;
            }
            this.buttonLoading = true;
            try {
              const res = await this.$http.post('/api/user/sendMsg',{mobile:phone})
              console.log('res',res);
              this.buttonLoading = false;
              console.log('res',res);
              if(res){
                this.codeTxt = '60s';
                let count = 60;
                const temInterval = setInterval(() => {
                  count --;
                  this.codeTxt = `${count}s`;
                  if(this.codeTxt === '0s'){
                    clearInterval(temInterval);
                    this.codeTxt = '获取验证码'
                  }
                }, 1000);
              }
            } catch (error) {
              this.buttonLoading = false;
              this.$toast(error.message||'请求失败');
            }
            
        },
    },
}
</script>
<style lang="scss" scoped>
input:-internal-autofill-selected{
    background: transparent !important;
}
.register{
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: url('../assets/bglogin.png');
    background-attachment:fixed;
    background-position:center;
    position: relative;
}
.content{
    width: 380px;
    position: absolute;
    left: 50%;
    margin-left: -190px;
    top: 100px;
    height: 100px;
    color: #fff;
    .title{
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
    .subtitle{
        font-size: 18px;
        font-weight: bold;
        padding-left: 20px;
        margin-top: 20px;
    }
}
.block{
    width: 380px;
    background: #3C72CA;
    border: 1px solid #FFFFFF;
    border-radius: 12px;
    margin-top: 30px;
    padding: 20px 0;
    box-sizing: border-box;
    .ext{
        text-align: right;
        width:317px;
        margin: auto;
        margin-bottom: 5px;
        span{
            display: inline-block;
        }
    }
}


.btn{
    width: 317px;
    margin: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color:#3699FA;
    background: #fff;
    margin-top: 25px;
    border-radius: 8px;
}
.active{
    cursor: pointer;
    background: #3699FA !important;
    color: #fff !important;
}

.ver-block{
    width:317px;
    margin: auto;
    position: relative;
    input{
        border: 1px solid #fff;
        border-radius: 9px;
        height: 37px;
        width: 205px;
        outline: none;
        background: transparent;
        padding-left: 10px;
        box-sizing: border-box;
    }
    .get-code{
        cursor: pointer;
        position: absolute;
        right: 0px;
        top: 2px;
        width: 100px;
        border-radius: 4px;
        padding: 9px 0px;
        text-align: center;
        background: #46A5FE;
        color: #fff;
        font-size: 13px;
    }
    
}

.zidingyi{
    border: 1px solid #fff;
    border-radius: 10px;
    width: 315px;
    height: 39px;
    margin: auto;
    margin-bottom: 15px;
    line-height: 39px;
    position: relative;
    .mark{
        display: inline-block;
        width: 18px;
        color: #FF0000;
        font-size: 14px;
        text-align: right;
    }
    .click-mod{
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        margin-right: 8px;
        cursor: pointer;
        span{
            display: inline-block;
            width: 7px;
            height: 7px;
            margin-right: 2px;
            background: #fff;
            border-radius: 50%;
            border: 1.5px solid #999797;
            vertical-align: middle;
        }
    }
    .selected{
        span{
            border: 1.5px solid #999797 !important;
            background: #41A9F0 !important;
        }
    }
}

a, a:link{
    color: #fff !important;
}

</style>
